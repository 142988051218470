<template>
  <Wrapper>
    <div class="mb-10 text-left">
      <h3 class="text-gray-600 text-lg font-bold">
        Registro uno a uno de entidades vigiladas
      </h3>
      <p class="text-gray-400 font-bold">
        Ingrese la información del usuario que registrará de manera individual
      </p>
    </div>
    <a-form-model
      layout="vertical"
      :model="form"
      class="max-w-md mx-auto flex flex-col"
    >
      <a-form-model-item label="Nombre abreviado de la entidad">
        <a-input v-model="form.name" placeholder="Ej: Banco de la républica" />
      </a-form-model-item>
      <a-form-model-item label="NIT">
        <a-input
          v-model="form.nit"
          placeholder="Ej: 000-000000000"
          :disabled="true"
        />
      </a-form-model-item>
      <a-form-model-item label="Código de la entidad">
        <a-input
          v-model="form.sfc_code"
          placeholder="número"
          :disabled="true"
        />
      </a-form-model-item>

      <a-form-model-item label="Tipo de entidad" prop="region">
        <a-select v-model="form.company_type" placeholder="Seleccione el tipo">
          <a-select-option
            v-for="company in formOptions.optionsCompanyType"
            :value="company.code"
            :key="company.code"
          >
            {{ company.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div v-if="feedback">
        <p class="my-2 text-xs text-center text-red-500">{{ feedback }}</p>
      </div>
      <div class="mt-4 flex flex-col md:flex-row gap-5">
        <a-button
          type="danger"
          @click="$router.go(-1)"
          shape="round"
          ghost
          :block="true"
          :loading="loading"
        >
          <span class="mx-2">CANCELAR</span>
        </a-button>
        <a-button
          type="primary"
          @click="updateCompany"
          shape="round"
          :block="true"
          :loading="loading"
        >
          <span class="mx-2">GUARDAR</span>
        </a-button>
      </div>
    </a-form-model>
  </Wrapper>
</template>

<script>
import Wrapper from "../../UI/Wrapper";
export default {
  components: { Wrapper },
  data() {
    return {
      value: 1,
      authorizationFiles: [],
      companyFile: null,
      feedback: "",
      loading: false,
      form: {
        name: "",
        nit: "",
        sfc_code: "",
        company_type: "",
      },
    };
  },
  mounted() {
    const { id } = this.$route.query;
    if (id) {
      this.getCompany(id);
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    async getCompany(id) {
      
      this.loading = true;
      let {error, data} = await this.$api.getCompany(id);
      this.loading = false;

      if (error) this.showToast("error", "Error consultando los datos de la entidad");

      if (data) this.form = { ...data };

    },
    async updateCompany() {
      if (!this.checkForm()) {
        return;
      }
      const myForm = { ...this.form };
      this.loading = true;
      let {error, data} = await this.$api.updateCompany(myForm, this.form.id);
      this.loading = false;

      if (error) this.showToast("error", "Error editando la entidad.");

      if (data) {
        if (data.code === 200) {
            this.showToast("success", "Entidad actualizada correctamente.");
            this.$router.go(-1);
          } else if (data.code === 401) {
            // NIT
            this.showToast("error", data.error);
          } else if (data.code === 402) {
            // Código y tipo de entidad
            this.showToast("error", data.error);
          }
      }
    },
    checkForm() {
      this.feedback = null;

      if (this.$validator.isEmpty(this.form.name)) {
        this.feedback = "Introduzca el nombre de la entidad.";
        return false;
      }
      if (this.$validator.isEmpty(this.form.company_type + "")) {
        this.feedback = "Seleccione el tipo de la entidad.";
        return false;
      }
      return true;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
  },
};
</script>

<style></style>
