<template>
  <div>
      <EditCompanyForm></EditCompanyForm>
  </div>
</template>

<script>
import EditCompanyForm from '../../../components/Dashboard/Companies/EditCompanyForm'
export default {
  components: { EditCompanyForm },

}
</script>

<style>

</style>